import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";

const MultiBackground = ({ children, className }) => {
  const { heroChat, heroFace, heroGraph } = useStaticQuery(
    graphql`
      query {
        heroChat: file(relativePath: { eq: "hero_chat.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroFace: file(relativePath: { eq: "hero_face.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroGraph: file(relativePath: { eq: "hero_graph.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  let hero;
  switch (className) {
    case "hero-chart":
      hero = heroChat;
      break;
    case "hero-face":
      hero = heroFace;
      break;
    case "hero-graph":
      hero = heroGraph;
      break;
    default:
      hero = heroChat;
  }
  const backgroundFluidImageStack = [
    hero.childImageSharp.fluid,
    `linear-gradient(180deg,
        rgba(12, 8, 1, 0.5),
        rgba(12, 8, 1, 0.5))`
  ].reverse();

  return (
    <BackgroundImage
      Tag={"section"}
      id={"test"}
      className={`hero ${className} js-hero`}
      fluid={backgroundFluidImageStack}
    >
      {children}
    </BackgroundImage>
  );
};

MultiBackground.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default MultiBackground;
